import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class DriverProfileService {
  constructor(private firestore: AngularFirestore) {}

  getProfile(driverID) {
    return this.firestore.collection('Drivers').doc(driverID).get();
  }

  getDrivers() {
    return this.firestore
      .collection('Drivers', (ref) => ref.orderBy('createdAt', 'asc'))
      .get();
  }

  getOnDutyDrivers() {
    return this.firestore
      .collection('Drivers', (ref) => ref.where('dutyStatus', '==', 'on-duty'))
      .get();
  }

  setDriverOnDuty(driverID) {
    return this.firestore.collection('Drivers').doc(driverID).update({
      dutyStatus: 'on-duty',
    });
  }

  setDriverOffDuty(driverID) {
    return this.firestore.collection('Drivers').doc(driverID).update({
      dutyStatus: 'off-duty',
    });
  }

  driverOnDelivery(driverID) {
    return this.firestore.collection('Drivers').doc(driverID).update({
      onDelivery: true,
    });
  }

  driverOffDelivery(driverID) {
    return this.firestore.collection('Drivers').doc(driverID).update({
      onDelivery: false,
    });
  }

  topUpWalletQR(
    customerID: string,
    rands: number,
    driverID,
    paymentMethod: string
  ) {
    const db = firebase.firestore();
    const walletRef = db.collection(`Users/${customerID}/Wallet`).doc('wallet');
    const increment = firebase.firestore.FieldValue.increment(rands);
    const time = firebase.firestore.FieldValue.serverTimestamp();
    return walletRef.update({
      rands: increment,
      lastQrTopUp: { driverID, paymentMethod, rands, time },
    });
  }

  topUpCallerWalletQR(
    docID: string,
    phoneNo: string,
    currentWallet: number,
    rands: number,
    driverID,
    paymentMethod: string
  ) {
    const time = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore
      .collection('CallUsers')
      .doc(docID)
      .update({
        wallet: currentWallet + rands,
        lastTopUp: {
          rands: rands,
          lastQrTopUp: { driverID, paymentMethod, rands, time },
        },
      });
  }

  updateCallerNames(docID: string, fullName: string) {
    return this.firestore.collection('CallUsers').doc(docID).update({
      customerName: fullName,
    });
  }

  getCallerWallet(phoneNo: string) {
    return this.firestore
      .collection('CallUsers', (ref) =>
        ref.where('customerCellphone', '==', phoneNo)
      )
      .get();
  }

  updateCallerWallet(
    walletDocID: string,
    phoneNo: string,
    newValue: number,
    orderDocID: string
  ) {
    return this.firestore
      .collection('CallUsers')
      .doc(walletDocID)
      .update({
        wallet: newValue,
      })
      .then(() => {
        this.firestore.collection('Orders').doc(orderDocID).update({
          paymentMethod: 'thuma-pay',
        });
      });
  }
}
