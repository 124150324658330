import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DriverProfileService } from 'src/app/services/driver-profile/driver-profile.service';
import { OrderService } from 'src/app/services/order/order.service';

@Component({
  selector: 'app-assign-drivers',
  templateUrl: './assign-drivers.page.html',
  styleUrls: ['./assign-drivers.page.scss'],
})
export class AssignDriversPage implements OnInit {
  @Input() docID;
  @Input() invoiceNo;

  drivers = [];
  loading: HTMLIonLoadingElement;

  constructor(
    private modalController: ModalController,
    private orderService: OrderService,
    private driversService: DriverProfileService,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.getDrivers();
  }

  closePage() {
    this.modalController.getTop().then((res) => {
      this.modalController.dismiss();
    });
  }

  getDrivers() {
    this.driversService.getOnDutyDrivers().subscribe((res) => {
      if (!res.empty) {
        this.drivers = res.docs.map((res) => res.data());
        this.drivers = this.drivers.sort((a, b) => b.createdAt - a.createdAt);
      }
    });
  }

  selectDriver(driverID) {
    console.log(`SELECT DRIVER ${driverID}`);
    console.log(this.docID);
    console.log(this.invoiceNo);
    this.presentLoading();
    if (this.docID && this.invoiceNo) {
      this.orderService
        .assignOrderDriver(this.docID, driverID)
        .then(() => {
          this.driversService
            .driverOnDelivery(driverID)
            .then(() => {
              const message = 'New order assigned to you';
              this.orderService.sendDriverNotification(driverID, message);
              this.dismissLoading();
              alert('Driver Assigned');
              this.closePage();
            })
            .catch((e) => {
              this.dismissLoading();
              console.log('!');
              console.log(e);
            });
        })
        .catch((e) => {
          this.dismissLoading();
          console.log('#');
          console.log(e);
        });
    }
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: 'loading...',
    });
    await this.loading.present();
  }

  async dismissLoading() {
    this.loading.dismiss();
  }
}
