import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'information/:id',
    loadChildren: () =>
      import('./pages/information/information.module').then(
        (m) => m.InformationPageModule
      ),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartPageModule),
  },
  {
    path: 'track-order',
    loadChildren: () =>
      import('./pages/track-order/track-order.module').then(
        (m) => m.TrackOrderPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'user-home',
    loadChildren: () =>
      import('./pages/user/user-home/user-home.module').then(
        (m) => m.UserHomePageModule
      ),
  },
  {
    path: 'user-history',
    loadChildren: () =>
      import('./pages/user/user-history/user-history.module').then(
        (m) => m.UserHistoryPageModule
      ),
  },
  {
    path: 'user-orders',
    loadChildren: () =>
      import('./pages/user/user-orders/user-orders.module').then(
        (m) => m.UserOrdersPageModule
      ),
  },
  {
    path: 'user-update',
    loadChildren: () =>
      import('./pages/user/user-update/user-update.module').then(
        (m) => m.UserUpdatePageModule
      ),
  },
  {
    path: 'store-add',
    loadChildren: () =>
      import('./pages/store/store-add/store-add.module').then(
        (m) => m.StoreAddPageModule
      ),
  },
  {
    path: 'store-change',
    loadChildren: () =>
      import('./pages/store/store-change/store-change.module').then(
        (m) => m.StoreChangePageModule
      ),
  },
  {
    path: 'store-home',
    loadChildren: () =>
      import('./pages/store/store-home/store-home.module').then(
        (m) => m.StoreHomePageModule
      ),
  },
  {
    path: 'store-orders',
    loadChildren: () =>
      import('./pages/store/store-orders/store-orders.module').then(
        (m) => m.StoreOrdersPageModule
      ),
  },
  {
    path: 'store-update',
    loadChildren: () =>
      import('./pages/store/store-update/store-update.module').then(
        (m) => m.StoreUpdatePageModule
      ),
  },
  {
    path: 'store-view',
    loadChildren: () =>
      import('./pages/store/store-view/store-view.module').then(
        (m) => m.StoreViewPageModule
      ),
  },
  {
    path: 'products/:id',
    loadChildren: () =>
      import('./pages/products/products.module').then(
        (m) => m.ProductsPageModule
      ),
  },
  {
    path: 'category/:id',
    loadChildren: () =>
      import('./pages/category/category.module').then(
        (m) => m.CategoryPageModule
      ),
  },
  {
    path: 'logged-home',
    loadChildren: () =>
      import('./pages/logged-in/logged-home/logged-home.module').then(
        (m) => m.LoggedHomePageModule
      ),
  },
  {
    path: 'logged-category',
    loadChildren: () =>
      import('./pages/logged-in/logged-category/logged-category.module').then(
        (m) => m.LoggedCategoryPageModule
      ),
  },
  {
    path: 'driver-home',
    loadChildren: () =>
      import('./pages/users/driver/driver-home/driver-home.module').then(
        (m) => m.DriverHomePageModule
      ),
  },
  {
    path: 'driver-request',
    loadChildren: () =>
      import('./pages/users/driver/driver-request/driver-request.module').then(
        (m) => m.DriverRequestPageModule
      ),
  },
  {
    path: 'driver-pickup',
    loadChildren: () =>
      import('./pages/users/driver/driver-pickup/driver-pickup.module').then(
        (m) => m.DriverPickupPageModule
      ),
  },
  {
    path: 'driver-drop-off',
    loadChildren: () =>
      import(
        './pages/users/driver/driver-drop-off/driver-drop-off.module'
      ).then((m) => m.DriverDropOffPageModule),
  },
  {
    path: 'driver-history',
    loadChildren: () =>
      import('./pages/users/driver/driver-history/driver-history.module').then(
        (m) => m.DriverHistoryPageModule
      ),
  },
  {
    path: 'driver-edit',
    loadChildren: () =>
      import('./pages/users/driver/driver-edit/driver-edit.module').then(
        (m) => m.DriverEditPageModule
      ),
  },
  {
    path: 'store-history',
    loadChildren: () =>
      import('./pages/store/store-history/store-history.module').then(
        (m) => m.StoreHistoryPageModule
      ),
  },
  {
    path: 'admin-home',
    loadChildren: () =>
      import('./pages/users/admin/admin-home/admin-home.module').then(
        (m) => m.AdminHomePageModule
      ),
  },
  {
    path: 'admin-users',
    loadChildren: () =>
      import('./pages/users/admin/admin-users/admin-users.module').then(
        (m) => m.AdminUsersPageModule
      ),
  },
  {
    path: 'admin-edit',
    loadChildren: () =>
      import('./pages/users/admin/admin-edit/admin-edit.module').then(
        (m) => m.AdminEditPageModule
      ),
  },
  {
    path: 'all-shops',
    loadChildren: () =>
      import('./pages/all-shops/all-shops.module').then(
        (m) => m.AllShopsPageModule
      ),
  },
  {
    path: 'user-cashier',
    loadChildren: () =>
      import('./pages/user/user-cashier/user-cashier.module').then(
        (m) => m.UserCashierPageModule
      ),
  },
  {
    path: 'user-withdraw',
    loadChildren: () =>
      import('./pages/user/user-withdraw/user-withdraw.module').then(
        (m) => m.UserWithdrawPageModule
      ),
  },
  {
    path: 'user-withdrawal-hist',
    loadChildren: () =>
      import(
        './pages/user/user-withdrawal-hist/user-withdrawal-hist.module'
      ).then((m) => m.UserWithdrawalHistPageModule),
  },
  {
    path: 'admin-payments',
    loadChildren: () =>
      import('./pages/users/admin/admin-payments/admin-payments.module').then(
        (m) => m.AdminPaymentsPageModule
      ),
  },
  {
    path: 'user-wallet',
    loadChildren: () =>
      import('./pages/user/user-wallet/user-wallet.module').then(
        (m) => m.UserWalletPageModule
      ),
  },
  {
    path: 'user-deposit',
    loadChildren: () =>
      import('./pages/user/user-deposit/user-deposit.module').then(
        (m) => m.UserDepositPageModule
      ),
  },
  {
    path: 'location',
    loadChildren: () =>
      import('./pages/location/location.module').then(
        (m) => m.LocationPageModule
      ),
  },
  {
    path: 'logged',
    loadChildren: () =>
      import('./pages/logged-in/logged/logged.module').then(
        (m) => m.LoggedPageModule
      ),
  },
  {
    path: 'make-order',
    loadChildren: () =>
      import('./pages/user/make-order/make-order.module').then(
        (m) => m.MakeOrderPageModule
      ),
  },
  {
    path: 'modal-pop',
    loadChildren: () =>
      import('./pages/modal-pop/modal-pop.module').then(
        (m) => m.ModalPopPageModule
      ),
  },
  {
    path: 'make-payment',
    loadChildren: () =>
      import('./pages/user/make-payment/make-payment.module').then(
        (m) => m.MakePaymentPageModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: 'payment-options',
    loadChildren: () =>
      import('./pages/user/payment-options/payment-options.module').then(
        (m) => m.PaymentOptionsPageModule
      ),
  },
  {
    path: 'wallet-method',
    loadChildren: () =>
      import('./pages/user/wallet-method/wallet-method.module').then(
        (m) => m.WalletMethodPageModule
      ),
  },
  {
    path: 'call-agent-home',
    loadChildren: () =>
      import(
        './pages/users/call-agent/call-agent-home/call-agent-home.module'
      ).then((m) => m.CallAgentHomePageModule),
  },
  {
    path: 'call-agent-orders',
    loadChildren: () =>
      import(
        './pages/users/call-agent/call-agent-orders/call-agent-orders.module'
      ).then((m) => m.CallAgentOrdersPageModule),
  },
  {
    path: 'call-agent-profile',
    loadChildren: () =>
      import(
        './pages/users/call-agent/call-agent-profile/call-agent-profile.module'
      ).then((m) => m.CallAgentProfilePageModule),
  },
  {
    path: 'call-agent-edit',
    loadChildren: () =>
      import(
        './pages/users/call-agent/call-agent-edit/call-agent-edit.module'
      ).then((m) => m.CallAgentEditPageModule),
  },
  {
    path: 'edit-order-modal',
    loadChildren: () =>
      import('./pages/modals/edit-order-modal/edit-order-modal.module').then(
        (m) => m.EditOrderModalPageModule
      ),
  },
  {
    path: 'agent-search-modal',
    loadChildren: () =>
      import(
        './pages/modals/agent-search-modal/agent-search-modal.module'
      ).then((m) => m.AgentSearchModalPageModule),
  },
  {
    path: 'app-orders',
    loadChildren: () =>
      import('./pages/users/call-agent/app-orders/app-orders.module').then(
        (m) => m.AppOrdersPageModule
      ),
  },

  {
    path: 'view-drivers',
    loadChildren: () =>
      import('./pages/users/call-agent/view-drivers/view-drivers.module').then(
        (m) => m.ViewDriversPageModule
      ),
  },
  {
    path: 'assign-drivers',
    loadChildren: () =>
      import('./pages/modals/assign-drivers/assign-drivers.module').then(
        (m) => m.AssignDriversPageModule
      ),
  },
  {
    path: 'qr-top-up',
    loadChildren: () =>
      import('./pages/users/driver/qr-top-up/qr-top-up.module').then(
        (m) => m.QrTopUpPageModule
      ),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('./pages/policy/policy.module').then((m) => m.PolicyPageModule),
  },  {
    path: 'qr-call-user',
    loadChildren: () => import('./pages/users/driver/qr-call-user/qr-call-user.module').then( m => m.QrCallUserPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
