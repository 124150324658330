// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAnPjcq_eKhBkVHgoTeb5abVZXfUnOLZKw',
    authDomain: 'thuma-mina-994da.firebaseapp.com',
    projectId: 'thuma-mina-994da',
    storageBucket: 'thuma-mina-994da.appspot.com',
    messagingSenderId: '403211744454',
    appId: '1:403211744454:web:ab4c9a3a77e6ae4c6053c7',
    // apiKey: 'AIzaSyC-alea6QKb3seWhpP0eHGO3fPQw5u5qVs',
    // authDomain: 'thuma-demo.firebaseapp.com',
    // projectId: 'thuma-demo',
    // storageBucket: 'thuma-demo.appspot.com',
    // messagingSenderId: '572929017566',
    // appId: '1:572929017566:web:9265d07c900082031fe44f',
  },
  mapKey: 'fC__OfeSKRNcax33xNWPzwN1c1FISQifNSj2TCoaJTQ',
  webUrl: 'https://delivery.2mamina.co.za',
  // yokoPK: 'pk_test_aae3e945P57487Ad22d4',
  yokoPK: 'pk_live_04d3de4fP57487Ab9c04',
  oneSignalKey: 'a142cc62-fc34-4a60-90c2-31222f9805eb',
  oneSignalRestKey: 'OGUwODlhZmMtY2Y4Zi00YWIyLThkOTYtYmNlMGZiOWE2MDEw',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
