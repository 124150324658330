import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  cartList = [];
  store = '';
  private requestBy;

  constructor(
    public alertController: AlertController,
    public toastCtrl: ToastController
  ) {}

  setRequestBy(userID) {
    this.requestBy = userID;
  }

  getRequestBy() {
    return this.requestBy;
  }

  addStore(store) {
    if (this.store === '') {
      this.store = store;
    }
  }

  addToCart(product) {
    let isFound = false;
    if (this.cartList.length > 0) {
      for (const currentItem in this.cartList) {
        if (this.cartList[currentItem].id === product.id) {
          const price = this.cartList[currentItem].price;
          this.cartList[currentItem].quantity += 1;
          this.cartList[currentItem].totalPrice += price;
          this.toast('Item added to cart', 'success');
          isFound = true;
        }
      }

      if (isFound === false) {
        this.cartList.push({
          ...product,
          quantity: 1,
          totalPrice: product.price,
        });
        this.toast('Item added to cart', 'success');
      }
    } else {
      this.cartList.push({
        ...product,
        quantity: 1,
        totalPrice: product.price,
      });
      this.toast('Item added to cart', 'success');
    }
  }

  removeItem(product) {
    const { id } = product;

    for (const currentItem in this.cartList) {
      if (this.cartList[currentItem].id === id) {
        if (this.cartList[currentItem].quantity > 1) {
          const price = this.cartList[currentItem].price;
          this.cartList[currentItem].totalPrice -= price;
          this.cartList[currentItem].quantity -= 1;
          break;
        }

        if (this.cartList[currentItem].quantity === 1) {
          this.cartList.splice(this.cartList.indexOf(product), 1);
          break;
        }
      }
    }

    this.toast('Item removed from cart', 'danger');
  }

  removeItems(product) {
    if (this.cartList.length > 0) {
      this.cartList.splice(this.cartList.indexOf(product), 1);
    }

    this.toast('Items removed from cart', 'danger');
  }

  getCartCount() {
    let total = 0;
    for (const currentItem of this.cartList) {
      total += currentItem.quantity;
    }

    return total;
  }

  getCartPrice() {
    let total = 0.0;
    for (const currentItem of this.cartList) {
      total += currentItem.totalPrice;
    }

    return Number(total.toFixed(2));
  }

  getCartItems() {
    return this.cartList;
  }

  clearCart() {
    this.presentAlertConfirm();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'Confirm Action!',
      message: '<strong>Are you sure you want to clear Cart</strong>?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Yes',
          handler: () => {
            this.continueClearCart();
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlert(msg) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();
  }

  continueClearCart() {
    if (this.cartList.length > 0) {
      this.cartList.splice(0, this.cartList.length);
      this.toast('Item removed from cart', 'danger');
    }
  }

  async toast(msg, toastColor) {
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      color: toastColor,
      duration: 3000,
    });

    toast.present();
  }
}
